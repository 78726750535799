export default {
  menu: {
    home: 'Home',
    features: 'Product',
    study: 'Case Study',
    company: 'Company',
    contact: 'Contact Us',
    dashboard: 'Dashboard',
  },
  home: {
    intro: {
      title: 'In-seat delivery with Messenger',
      subtitle: 'A chatbot that allows fans to easily have their food delivered to their seat without missing any of the action',
      learnMore: 'or learn more',
    },
    body: {
      study: {
        cardType: 'News',
        cardTitle: 'Launch with the Portland Pickles',
        cardSubtitle: '',
        cardBtn: 'View Case Study',
        subtitle0: 'Sales Increase',
        subtitle1: 'Conversion Rate',
        subtitle2: 'Retention Rate',
        subtitle3: 'Order Total Increase',
      },
      type: 'Chatler for ',
      type1: 'Venues',
      title1: 'Adding a sales channel to your venue',
      p1: 'During a game, if a customer wants to order something, he either has to wait for a pause and stand in line, or miss a part of the action. Venues lose on potential sales. We help them unlock their full sales potential.',
      type2: 'Operations',
      title2: 'Reducing concession lines',
      p2: 'Waiting in line is the number one problem fans face in stadiums. Nobody likes to wait in line and when they get overcrowded, concessions lose their efficiency. By distributing the demand, we help reduce wait times for everyone, including customers that don\'t even use our service.',
      type3: 'Fans',
      title3: 'Improving the fan experience',
      p3: 'No more missing home runs because you were busy getting beers for your friends. No more waiting in lines. With a frictionless experience, fans can get their food and drinks delivered right to their seats. It’s also way better than an app. Nothing to download, no account to create, the fan can focus on what\'s really important. It\'s like having your own waiter!',
    },
  },
  stats: {
    conversion: 'Conversion Rate',
    retention: 'Retention Rate',
    salesVolume: 'F&B Sales Volume Increase',
    orderPerCustomer: 'Avg. Orders Per Customer',
    orderTotal: 'Order Total Increase per Customer',
    deliveryTime: 'Avg. Delivery Time',
    satisfaction: 'Likely to recommend Chatler',
  },
  features: {
    intro: {
      title: 'Frictionless Mobile Ordering',
      subtitle: 'Eliminate the sales barrier between your customers and your venue.',
      feature1Title: 'Frictionless',
      feature1Subtitle: 'Beats traditional apps. Just order and pay.',
      feature2Title: 'Intuitive',
      feature2Subtitle: 'Nothing easier to use than a conversational interface.',
      feature3Title: 'Adaptable',
      feature3Subtitle: 'Sell a wide variety of products',
      feature4Title: 'Easy to Manage',
      feature4Subtitle: 'Prepare, dispatch and deliver orders',
    },
    body: {
      featureType1: 'Chatbot',
      featureType2: 'Order Manager',
      tradionalApp: 'Traditional App',
      bulletList1: 'Scan Code',
      bulletList2: 'Order Food',
      bulletList3: 'Pay',
      bulletList4: 'Download App',
      bulletList5: 'Figure Out How to Use it',
      bulletList6: 'Create Account',
      bulletList7: 'Order Food',
      bulletList8: 'Enter Seat Location',
      bulletList9: 'Pay',
      title1: 'A full key-in-hand solution for your venue',
      p1: 'For your customers, our chatbot allows frictionless ordering, engaging them in ways that were previously unheard of. For your staff, our Order Manager App is a seamless integration to your concessions, making them easily prepare, dispatch and deliver orders on time.',
      title2: 'Frictionless Ordering',
      p2: 'Unlike traditional apps, chatbots offer a seamless experience where fans can order the moment they have an impulse. There\'s no app to download, no account to create and no seat location to input. Your customer can focus on what really matters.',
      title3: 'Intuitive Experience',
      p3: 'There is nothing easier to use than a conversational interface. Everyone with a smartphone knows how to send a text. The same can\'t be said for a majority of mobile apps, where the user experience is mediocre at best.',
      title4: 'Sell a wide variety of products',
      p4: 'From hotdogs, pretzels to baseball caps, you can sell anything on our platform. Your customers don\'t have to wait in line to purchase souvenirs or memorabilia; they can have it delivered directly to their seats.',
      title5: 'Prepare, dispatch and deliver your orders',
      p5: 'Our Order Manager is a iOS and Android app for your concessions and runners with a focus on speed and efficiency. Concessions receive and prepare orders, while runners pick them up and deliver them.',
      },
  },
  company: {
    intro: {
      title: 'It\'s a butler that chats. Your Chatler.',
      subtitle: 'At Chatler, we make it our mission to remove the barriers between venues and their customers.',
    },
    body: {
      title1: 'Who are we?',
      p1: 'We\'re a young startup from Montreal who build products that people love. We are a team of engineers and designers who focus on getting stuff done quickly with the highest amount quality possible.',
      title2: 'Why are we doing this?',
      p2l1: 'Apps are out. Chatbots are in. The app market is over saturated in unengaging apps. Everyone is building an app whether it\'s a movie theatre, a train station, a stadium or an airport. The answer to every problem shouldn\'t be: "Let\'s build an app for that".',
      p2l2: 'That\'s where we come in. Chatbots are perfect for this. There are no apps to download, no accounts to create and no location to enter. Chatbots offer a frictionless experience that most closely ressembles how we interact with the world around us.',
    },
  },
  study: {
    intro: {
      title: 'Walker Stadium Launch',
      subtitle: 'Here\'s our case study from the 2018 Chatler launch at the Walker Stadium in Portland.',
      btn1: 'Try It Out',
      btn2: 'View Features',
    },
    body: {
      title1: 'Intro',
      p1p1: 'In the Summer of 2018, the Chatler team contacted the Portland Pickles, a collegiate baseball team, to offer their in-seat delivery software and conduct a trial. We wanted to see what a frictionless ordering experience would bring to the fans and to the venue.',
      p1p2: 'We even decided as the founders, to do the deliveries ourselves get as much knowledge as possible about the process. The goal was not only to build the best product for the fans, but for the staff as well. Our results were overwhelmingly positive.',
      title2: 'Generating Additional Revenue',
      p2: 'We help venues reach their full sales potential. We found that customers often abandoned purchases simply because the concessions lines were too long and because they didn\'t want to miss the game.',
      title3: 'Reducing concession waiting times',
      p3: 'We all know that fans don\'t enjoy lines, but the same can be said for concession staff. We help them reduce their charge during the game and serving customers more efficiently.',
      title4: 'Improving the fan experience',
      p4: 'The Pickles\' fans loved Chatler. Their response was far more positive than we could have imagined. What we found is that we helped them overcome three common pain points. Nobody likes to wait in line, no one wants to miss precious game time and lastly, if fans can avoid carrying cash or paying ATM fees, they will.',
      testimonial1: 'Chatler kept me tuned into the game and well fed by delivering my food and beverages right to my seat.  Having in-seat service was one of the best additions to the Walker Stadium this year.',
      author1: '-David Shenson, Pickles Fan',
      testimonial2: 'No standing in lines and no need to pay ATM fees for cash lines. Chatler service is fast and friendly. This is how all teams will someday provide top notch customer service. Brilliant!',
      author2: '-John Windus, News Reporter',
      testimonial3: 'Chatler was awesome. It allowed me to stay engaged with the game and those that are sitting next to me without missing an opportunity to  have food and drink whenever the need arose.  The App was easy to use and I love not having to bring cash to the game.',
      author3: '-Tory McVay, Pickles Fan',
      testimonial4: 'We used Chatler on several occasions at the Portland Pickles games. The App is easy to use, the staff was very friendly and the service was fast.',
      author4: '-Yvonne McVay, Pickles Fan',
    },
  },
  form: {
    email: 'Email',
    fName: 'First Name',
    lName: 'Last Name',
    company: 'Company',
  },
  button: {
    demo: 'View Demo',
  },
  footer: {
    pages: 'Contents',
    resources: 'Resources',
    faq: 'FAQ',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    social: 'Social',
  },
  faq: {
    title: 'Frequently Asked Questions',
    h1: 'Users',
    s1: 'I sent my order to the wrong location. What do I do?',
    p1: 'No problem! Call us at 1-819-446-4564 or send us a message at contact@chatler.io with your first and last name accompanied with your seat number. We will make sure you have your order delivered.',
    s2: 'I made a mistake with my order. How do I change it?',
    p2: 'Unfortunately, it is not possible to modify an order after having paid. Call us as soon as possible at 819-446-4564 to cancel it and we will make sure to have you refunded. You will be able to start an order immediately after.',
    s3: 'How do I qualify for a refund?',
    p3: 'You may obtain a refund if a) we cannot fulfill your order or b)you order alcohol and you are under the age limit.',
    s4: 'You guys rock. How do I spread the love?',
    p4: 'You can leave a review and a like on Facebook, send us a message at contact@chatler.io, or ask your favorite sports team for our service.',
  },
  privacy: {
    title: 'Privacy Policy',
    h1: 'Section 1 - What do we do with your information?',
    p1: 'We may use your Personal Information for the following purposes:',
    l1: 'To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.',
    l2: 'To allow us to better service you in responding to your customer service requests.',
    l3: 'To quickly process your transactions.',
    l4: 'To follow up with them after correspondence (live chat, email or phone inquiries)',
    h2: 'Section 2 - Consent',
    s2p1: 'How do we get your consent?',
    p2p1: 'We collect Personal Information on the App when you submit it to us, such as when getting started with the application, or submitting a request or comment on the website. In addition, when you access the App our system may automatically recognize and collect information regarding your IP address, operating system software and similar technical data. We accumulate and report statistical information on App usage and use this information to monitor and improve the App. We also use technology that shows us how our customers use our App and allow us to give you a more personalized experience when you use our App. We may combine the data collected by this technology with Personal Information you give us in order to send you information that may be of interest.',
    s2p2: 'How do we withdraw your consent?',
    p2p2: 'If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at contact@chatler.io.',
    h3: 'Section 3 - Third-Party Services',
    p3p1: 'In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.',
    p3p2: 'However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.',
    p3p3: 'In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.',
    p3p4: 'Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.',
    h4: 'Section 4 - Security',
    p4: 'We maintain reasonable administrative, technical and physical safeguards in an effort to protect against theft, loss, unauthorized access, use, modification and disclosure of Personal Information in our custody or control. Chatler uses no encryption (data scrambling) on certain portions of the App, but uses encryption on portions where you are transmitting financial information, such as credit card information. When you are asked for confidential information, you should check to see if the information being transmitted is encrypted in order to increase the security of your information. Unfortunately, no collection or transmission of information over the Internet or other publicly accessible communications networks can be guaranteed to be 100% secure, and therefore, we cannot ensure or warrant the security of any such information.',
    h5: 'Section 5 - Age of consent',
    p5: 'By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.',
    h6: 'Section 6 - Changes to this privacy policy',
    p6: 'The effective date of this notice and any revised or changed notice may be found on this page, on the bottom left corner of the notice. As we continue to develop our App and take advantage of technologies to improve the services we provide, we may have a need to change our current privacy policies. We therefore reserve the right, at our discretion, to change, modify, add, or remove portions of this Privacy Policy Statement at any time. Any changes will be effective only after the effective date of the change and will not affect any dispute arising prior to the effective date of the change.',
    h7: 'Section 7 - Questions and Contact Information',
    p7: 'If you have questions about this Privacy Policy Statement or the manner in which we treat your Personal Information, please email us at contact@chatler.io',
  },
  terms: {
    title: 'Terms of Service',
    h1: 'Overview',
    p1p1: 'This website is operated by Chatler. Throughout the site, the terms “we”, “us” and “our” refer to Chatler. Chatler offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.',
    p1p2: 'By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content',
    p1p3: 'Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.',
    p1p4: 'Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.',
    p1p5: 'Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.',
    h2: 'Section 1 - Terms',
    p2: 'By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.',
    h3: 'Section 2 - General Conditions',
    p3: 'We reserve the right to refuse service to anyone for any reason at any time. You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us. The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.',
    h4: 'Section 3 - Accuracy, Completeness and timeliness of information',
    p4: 'We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.',
    h5: 'Section 4 - Modifications to the service and prices',
    p5: 'Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.',
    h6: 'Section 5 - Products or services',
    p6: 'Certain products or services may be available exclusively online through the chatbot. We have made every effort to display as accurately as possible the colors and images of our products that appear at the concession. We cannot guarantee that your phone\'s display of any color will be accurate. We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited. We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.',
    h7: 'Section 6 - Accuracy of billing and account information',
    p7p1: 'We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the messenger account provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.',
    p7p2: 'You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.',
    h8: 'Section 7 - Optional Tools',
    p8: 'We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.',
    h9: 'Section 8 - Third-party links',
    p9p1: 'Certain content, products and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.',
    p9p2: 'We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party\'s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.',
    h10: 'Section 9 - User comments, feedback and other submissions',
    p10: 'If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, \'comments\'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service. You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.',
    h11: 'Section 10 - Personal Information',
    p11: 'Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.',
    h12: 'Section 11 - Errors, inaccuracies and omissions',
    p12p1: 'Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).',
    p12p2: 'We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.',
    h13: 'Section 12 - Prohibited Uses',
    p13: 'In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, har defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.',
    h14: 'Section 13 - Disclaimer of warranties; limitation of liability',
    p14p1: 'We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.',
    p14p2: 'You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided \'as is\' and \'as available\' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. In no case shall Chatler, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.',
    h15: 'Section 14 - Indemnification',
    p15: 'You agree to indemnify, defend and hold harmless Chatler and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.',
    h16: 'Section 15 - Severability',
    p16: 'In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.',
    h17: 'Section 16 - Termination',
    p17: 'The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).',
    h18: 'Section 17 - Entire Agreement',
    p18: 'The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.',
    h19: 'Section 18 - Governing Law',
    p19: 'These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Montréal Quebec CA.',
    h20: 'Section 19 - Changes to terms of service',
    p20: 'You can review the most current version of the Terms of Service at any time at this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.',
    h21: 'Section 20 - Contact Information',
    p21: 'Questions about the Terms of Service should be sent to us at contact@chatler.io.',
  },
};
